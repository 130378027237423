import Alpine from "alpinejs";
import { Splide } from '@splidejs/splide';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';
// Default theme
import '@splidejs/splide/css';

// Defining the contactFormHandler function
function contactFormHandler() {
    return {
        submitted: false,
        errorMessage: '',
        submitForm() {
            const formData = new FormData();
            formData.append('firstName', document.getElementById('firstName').value);
            formData.append('lastName', document.getElementById('lastName').value);
            formData.append('email', document.getElementById('email').value);
            formData.append('subject', document.getElementById('subject').value);
            formData.append('message', document.getElementById('message').value);
            formData.append('newsletter', document.getElementById('newsletter').checked ? 'Yes' : 'No');


            fetch('submit_form.php', {
                method: 'POST',
                body: formData,
            })
                .then(response => response.json())
                .then(data => {
                    if (data.success) {
                        this.submitted = true;
                    } else {
                        this.errorMessage = data.error || 'An error occurred. Please try again.';
                    }
                })
                .catch(error => {
                    this.errorMessage = 'An error occurred. Please try again.';
                });
        }
    }
}

// Making contactFormHandler globally available
window.contactFormHandler = contactFormHandler;

// Alpine.js initialization
window.Alpine = Alpine;

document.addEventListener('DOMContentLoaded', function () {
    Alpine.start();

    var video = document.querySelector('#sustainability video');
    if (video) {
        video.addEventListener('canplay', function() {
            this.play();
        });
    }

    if(document.getElementsByClassName('text-ticker').length > 0) {
        const splide = new Splide('.text-ticker .splide', {
            type: 'loop',
            drag: 'free',
            focus: 'center',
            arrows: false,
            pagination: false,
            autoWidth: true,
            autoScroll: {
                speed: -1,
            },
        });

        splide.mount({ AutoScroll });
    }

    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
        anchor.addEventListener('click', function (e) {
            e.preventDefault();

            document.querySelector(this.getAttribute('href')).scrollIntoView({
                behavior: 'smooth'
            });
        });
    });

    document.addEventListener('scroll', function() {
        let elements = document.querySelectorAll('.animate-up');

        let scrollTop = window.pageYOffset || document.documentElement.scrollTop;

        for (let i = 0; i < elements.length; i++) {
            if (scrollTop + window.innerHeight > elements[i].offsetTop) {
                elements[i].style.transform = "translateY(0)";
                elements[i].style.opacity = "1";
            }
        }
    });

    var animateUp = document.querySelector('.fade-up-effect');
    var observer = new IntersectionObserver(function(entries) {
        // Loop over the entries
        entries.forEach(function(entry) {
            // If the element is visible
            if (entry.isIntersecting) {
                // Add the animation class
                animateUp.classList.add('show');
            }
        });
    }, { threshold: 0.25 }); // Trigger when element is 25% in view
    observer.observe(animateUp);

    var fadeUpTextElement = document.querySelector('.fade-up-text');
    var observer = new IntersectionObserver(function(entries) {
        entries.forEach(function(entry) {
            if (entry.isIntersecting) {
                fadeUpTextElement.classList.add('show');
            }
        });
    }, { threshold: 0.4 }); // Trigger when element is 40% in view
    observer.observe(fadeUpTextElement);
});
